.comments {
  height: 5rem !important;
  resize: none;
}

.emailSubject {
  max-width: 57.8rem;
}

.emailBody {
    height: 8rem !important;
    resize: none;
    max-width: 57.8rem;
}

.search-results {
  max-height: 34.8rem;
  overflow: auto;
}

.search-results > :first-child {
  background: white;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

.search-results .table-row-hover {
  margin: 0.2rem;
}

.single-row-column-spacing {
  paddingleft: 0;
  paddingright: 3;
}
