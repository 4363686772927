.display-linebreak {
    white-space: pre-line;
  }

  .formHeadingsLarge {
    font-size: 1.25rem;
    letter-spacing: 0.15px;
    font-weight: 600;
}

.formHeadingsMedium {
    font-size: 1.00rem;
    letter-spacing: 0.15px;
    font-weight: 600;
}

.formHeadingsSmall {
    font-size: 0.75rem;
    letter-spacing: 0.15px;
    font-weight: 600;
}

.submitButton {
    width: 6em;
    height: 2em;
}

.logo {
    align-content: center;
}

.errorOrLoadingText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: calc(100vh - 55px); 
}