.header-wrapper {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1010;
    background-color: #fafafa;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    padding: 0.75rem 1rem;
    display: flex;
    justify-content: space-between;
  }
  
.system-award {
    white-space: nowrap
}