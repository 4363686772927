/* Light */
@font-face {
  font-family: 'proximanova';
  src: url('./fonts/ProximaNova-Light.eot');
  src: url('./fonts/ProximaNova-Light.eot?#iefix') format('embedded-opentype'),
       url('./fonts/ProximaNova-Light.otf') format('opentype');
  font-weight:light;
  font-weight: 300;
  font-style: normal;
}

/* Regular */
@font-face {
  font-family: 'proximanova';
  src: url('./fonts/ProximaNova-Reg.eot');
  src: url('./fonts/ProximaNova-Reg.eot?#iefix') format('embedded-opentype'),
       url('./fonts/ProximaNova-Reg.otf') format('opentype');
  font-weight:normal;
  font-weight: 400;
  font-style: normal;
}

/* Semibold */
@font-face {
  font-family: 'proximanova';
  src: url('./fonts/ProximaNova-Sbold.eot');
  src: url('./fonts/ProximaNova-Sbold.eot?#iefix') format('embedded-opentype'),
       url('./fonts/ProximaNova-Sbold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

body {
  font-family: 'proximanova', 'Arial', sans-serif;
  height: 100%; 
  --input-background-color: #f5f5f5;
  --input-border-color: rgba(0,0,0,.42);
  --input-border-style: .125rem solid var(--input-border-color)
}

.custom-input {
  border: none;
  background-color: var(--input-background-color);
  padding: .5rem 0;
  border-bottom: var(--input-border-style);
  text-align: center
}

.custom-select {
  padding: .5rem 0;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  user-select: none;
  background-color: var(--input-background-color);
  text-overflow: ellipsis;
}

.flex-basis-10 {
  flex-basis: 10%
}

.min-width-0 {
  min-width: 0;
}

.ellipsis {
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}

button.not-allowed:hover, a.not-allowed:hover {
    cursor:not-allowed
}