.award-content {
    max-width: 50rem;
}

.award-nav-item.active {
    background-color: rgba(0,0,0,.04)
}

.award-nav-item-name {
    display: inline-block;
    width: 10rem;
}

.award-category-label {
    overflow-wrap: break-word;
    word-break: break-all;
}

.caps-waivers-tooltip {
    background-color: var(--blue);
    max-width: 100%;
    text-align: left;
    white-space: pre-wrap;
}

#capsAndWaiversTable {
    width: 100%;
}

#capsAndWaiversTable td {
    padding-bottom: 0.3rem;
    padding-top: 0.3rem;
}

.combine-select-container {
    width: 10.1rem;
}

@media only screen and (max-width: 568px) {
    .combine-select-container {
        width: 7.2rem;
    }
}

@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
    #capsAndWaiversTable table, thead, tbody, th, td, tr { /* force table to not be a table anymore */
        display: block;
    }

    #capsAndWaiversTable thead tr { /* hide table headers (without using display, to support accessibility) */
        left: -9999px;
        position: absolute;
        top: -9999px;
    }

    #capsAndWaiversTable tr:first-of-type { /* first row has extra padding already */
        padding-top: 0;
    }

    #capsAndWaiversTable tr {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-bottom: 1rem;
        padding-top: 1rem;
    }
    
    #capsAndWaiversTable td { /* behave like a row */
        margin-top: 0.1rem;
        margin-bottom: 0.1rem;
        min-height: 2rem;
        padding-left: 9rem;
        position: relative;
    }

    #capsAndWaiversTable td:before { /* behave like a table header */
        content: attr(data-header);
        font-weight: bold;
        left: 0rem;
        position: absolute;
        white-space: nowrap;
    }
}

@media (max-width: 576px) {
    .flex-xs-wrap {
        flex-wrap: wrap !important;
    }
}

/* custom flex wrap to get combinedAwardLetterSendOptionDate to wrap inline with other combined award dates */
@media (max-width: 769px) {
    .flex-wrap-combinedAwardLetterSendOptionDate {
        flex-wrap: wrap !important;
    }
}

.inline-center {
    display: flex;
    justify-content: center;
    margin-bottom: 0;
}

.skip-to-content {
    text-align: center;
}

.break-word {
    word-break: break-word;
}