.viewer {
    position: absolute;
    left:0;
    height: 80%;
    width: 100%;
}

.scroll-wrapper-viewer {
    -webkit-overflow-scrolling: touch !important;;
    overflow-y: scroll !important;;
    position: absolute;
    left:0;
    height: 80%;
    width: 100%;

    /* important:  dimensions or positioning here! */
}

.scroll-wrapper-viewer iframe {
    /* nada! */
    height: 100%;
    width: 100%;

}
