.criteria-input-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none;
    width: 100%;
    text-align: left
}
.criteria-sidebar {
    flex-basis: 25%
}
.criteria-details {
    flex-basis: 75%
}
.criteria-input-wrapper.active {
    background-color: rgba(0, 0, 0, .04)
}
.criteria-list {
    list-style: none;
}
.rules-item {
    flex-basis: 50%;
    flex-grow: 0;
    display: flex
}
.rules-item .range {
    flex-basis: 20%;
    flex-grow: 0;
}
.rules-item .description {
    flex-basis: 80%;
    flex-grow: 0;
}
@media (min-width: 768px) {
.rules-item .range {
    flex-basis: 15%;
    flex-grow: 0;
}
.rules-item .description {
    flex-basis: 85%;
    flex-grow: 0;
}
}
.comment-box {
    flex-basis: 50%;
}
.comment-box textarea {
    resize: vertical;
    overflow: auto;
}
.comment-wrapper .comment-box:first-child {
    margin-right: 1rem;
}
.comment-wrapper .comment-box:last-child {
    margin-left: 1rem;
}
.reviewer-custom-btn {
    text-transform: none;
}