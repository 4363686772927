
.app-body {
    padding: 1rem;
}

[tabindex="-1"] {
    outline: none;
}
  
.App #osunavbar {
    margin-left: 0rem;
    margin-right: 0rem;
    position: relative;
}
.App #osunavbar .container, .app-body {
    max-width: var(--page-width);
    margin: 0 auto;
}
.fb-40 {
    flex-basis: 40%
}
.fb-20 {
    flex-basis: 20%
}
.hat {
    position:absolute;
    left:-10000px;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden
}

.word-wrap {
    /* Warning: Needed for oldIE support, but words are broken up letter-by-letter */
    -ms-word-break: break-all;
    word-break: break-all;
    /* Non standard for webkit */
    word-break: break-word;

    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

/* Fixes color contrast violation for react-select placeholder */
[class*="-control"] [class*="-placeholder"] {
    color: hsl(0, 0%, 40%) !important;
}

.heading5 {
    font-size: 1.5rem;
    font-weight: 400;
    letter-spacing: 0px;
}

.heading6 {
    font-size: 1.25rem;
    letter-spacing: 0.15px;
    font-weight: 600;
}

.value-match-red-row-highlight {
    background-color:#fff3cd
}

.required::after {
    content: "*";
    color: red;
}

a[aria-disabled="true"] {
    color: currentColor;
    display: inline-block;  /* For IE11/ MS Edge bug */
    opacity: 0.5;
    pointer-events: none;
    text-decoration: none;
}

footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 16rem; 
    background-color:rgb(250, 250, 250);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
}

footer a {
    color: rgb(2, 101, 202) !important;
}

html,
body {
 height: 100%;
 position: relative;
}

.main-container {
 min-height: 100vh; /* will cover the 100% of viewport */
 overflow: hidden;
 display: block;
 position: relative;
 padding-bottom: 16rem; /* height of your footer */
}