.file-button {
    background: #ffffff;
    border: solid 1px var(--blue);
    color: var(--blue);
    text-decoration: none;
    font-size: 1rem;
    cursor: pointer;
    text-align: center;
    transition: background 250ms ease-in-out, 
                transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-weight: 600;
    border-radius: 0.25rem;
    font-size: .875rem;
    padding: .25rem .5rem;
    &:hover {
        background: var(--blue-dark);
        color: white
    }
    &:disabled {
        background: transparent;
        color: var(--blue);
        opacity: .65;
        cursor: default;
    }
}