/* Light */
@font-face {
  font-family: 'proximanova';
  src: url(https://gradawards.osu.edu/static/media/ProximaNova-Light.ff9c4a8d.eot);
  src: url(https://gradawards.osu.edu/static/media/ProximaNova-Light.ff9c4a8d.eot?#iefix) format("embedded-opentype"), url(https://gradawards.osu.edu/static/media/ProximaNova-Light.076d851b.otf) format("opentype");
  font-weight: light;
  font-weight: 300;
  font-style: normal; }

/* Regular */
@font-face {
  font-family: 'proximanova';
  src: url(https://gradawards.osu.edu/static/media/ProximaNova-Reg.d9d162d5.eot);
  src: url(https://gradawards.osu.edu/static/media/ProximaNova-Reg.d9d162d5.eot?#iefix) format("embedded-opentype"), url(https://gradawards.osu.edu/static/media/ProximaNova-Reg.410504d4.otf) format("opentype");
  font-weight: normal;
  font-weight: 400;
  font-style: normal; }

/* Semibold */
@font-face {
  font-family: 'proximanova';
  src: url(https://gradawards.osu.edu/static/media/ProximaNova-Sbold.16c3736b.eot);
  src: url(https://gradawards.osu.edu/static/media/ProximaNova-Sbold.16c3736b.eot?#iefix) format("embedded-opentype"), url(https://gradawards.osu.edu/static/media/ProximaNova-Sbold.6a386899.otf) format("opentype");
  font-weight: 600;
  font-style: normal; }

body {
  font-family: 'proximanova', 'Arial', sans-serif;
  height: 100%;
  --input-background-color: #f5f5f5;
  --input-border-color: rgba(0,0,0,.42);
  --input-border-style: .125rem solid var(--input-border-color)
; }

.custom-input {
  border: none;
  background-color: var(--input-background-color);
  padding: .5rem 0;
  border-bottom: var(--input-border-style);
  text-align: center; }

.custom-select {
  padding: .5rem 0;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: var(--input-background-color);
  text-overflow: ellipsis; }

.flex-basis-10 {
  -webkit-flex-basis: 10%;
          flex-basis: 10%; }

.min-width-0 {
  min-width: 0; }

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

button.not-allowed:hover, a.not-allowed:hover {
  cursor: not-allowed; }

.innerRefresh {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding-left: 1em;
}

.outerRefresh {
    display: -webkit-flex;
    display: flex;
}
.innerRefresh {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding-left: 1em;
}

.outerRefresh {
    display: -webkit-flex;
    display: flex;
}
.criteria-input-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    border: none;
    width: 100%;
    text-align: left
}
.criteria-sidebar {
    -webkit-flex-basis: 25%;
            flex-basis: 25%
}
.criteria-details {
    -webkit-flex-basis: 75%;
            flex-basis: 75%
}
.criteria-input-wrapper.active {
    background-color: rgba(0, 0, 0, .04)
}
.criteria-list {
    list-style: none;
}
.rules-item {
    -webkit-flex-basis: 50%;
            flex-basis: 50%;
    -webkit-flex-grow: 0;
            flex-grow: 0;
    display: -webkit-flex;
    display: flex
}
.rules-item .range {
    -webkit-flex-basis: 20%;
            flex-basis: 20%;
    -webkit-flex-grow: 0;
            flex-grow: 0;
}
.rules-item .description {
    -webkit-flex-basis: 80%;
            flex-basis: 80%;
    -webkit-flex-grow: 0;
            flex-grow: 0;
}
@media (min-width: 768px) {
.rules-item .range {
    -webkit-flex-basis: 15%;
            flex-basis: 15%;
    -webkit-flex-grow: 0;
            flex-grow: 0;
}
.rules-item .description {
    -webkit-flex-basis: 85%;
            flex-basis: 85%;
    -webkit-flex-grow: 0;
            flex-grow: 0;
}
}
.comment-box {
    -webkit-flex-basis: 50%;
            flex-basis: 50%;
}
.comment-box textarea {
    resize: vertical;
    overflow: auto;
}
.comment-wrapper .comment-box:first-child {
    margin-right: 1rem;
}
.comment-wrapper .comment-box:last-child {
    margin-left: 1rem;
}
.reviewer-custom-btn {
    text-transform: none;
}

.app-body {
    padding: 1rem;
}

[tabindex="-1"] {
    outline: none;
}
  
.App #osunavbar {
    margin-left: 0rem;
    margin-right: 0rem;
    position: relative;
}
.App #osunavbar .container, .app-body {
    max-width: var(--page-width);
    margin: 0 auto;
}
.fb-40 {
    -webkit-flex-basis: 40%;
            flex-basis: 40%
}
.fb-20 {
    -webkit-flex-basis: 20%;
            flex-basis: 20%
}
.hat {
    position:absolute;
    left:-10000px;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden
}

.word-wrap {
    /* Warning: Needed for oldIE support, but words are broken up letter-by-letter */
    -ms-word-break: break-all;
    word-break: break-all;
    /* Non standard for webkit */
    word-break: break-word;

    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

/* Fixes color contrast violation for react-select placeholder */
[class*="-control"] [class*="-placeholder"] {
    color: hsl(0, 0%, 40%) !important;
}

.heading5 {
    font-size: 1.5rem;
    font-weight: 400;
    letter-spacing: 0px;
}

.heading6 {
    font-size: 1.25rem;
    letter-spacing: 0.15px;
    font-weight: 600;
}

.value-match-red-row-highlight {
    background-color:#fff3cd
}

.required::after {
    content: "*";
    color: red;
}

a[aria-disabled="true"] {
    color: currentColor;
    display: inline-block;  /* For IE11/ MS Edge bug */
    opacity: 0.5;
    pointer-events: none;
    text-decoration: none;
}

footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 16rem; 
    background-color:rgb(250, 250, 250);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
}

footer a {
    color: rgb(2, 101, 202) !important;
}

html,
body {
 height: 100%;
 position: relative;
}

.main-container {
 min-height: 100vh; /* will cover the 100% of viewport */
 overflow: hidden;
 display: block;
 position: relative;
 padding-bottom: 16rem; /* height of your footer */
}
.header-wrapper {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1010;
    background-color: #fafafa;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    padding: 0.75rem 1rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }
  
.system-award {
    white-space: nowrap
}

.tabs-no-text-select {
    -webkit-user-select:none;
    -ms-user-select:none;
    -o-user-select:none;
    user-select:none;
}
.comments {
  height: 5rem !important;
  resize: none;
}

.emailSubject {
  max-width: 57.8rem;
}

.emailBody {
    height: 8rem !important;
    resize: none;
    max-width: 57.8rem;
}

.search-results {
  max-height: 34.8rem;
  overflow: auto;
}

.search-results > :first-child {
  background: white;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

.search-results .table-row-hover {
  margin: 0.2rem;
}

.single-row-column-spacing {
  paddingleft: 0;
  paddingright: 3;
}

.date-info-tooltip {
    background-color: var(--blue);
    text-align: left;
    white-space: pre-wrap;
}
.award-content {
    max-width: 50rem;
}

.award-nav-item.active {
    background-color: rgba(0,0,0,.04)
}

.award-nav-item-name {
    display: inline-block;
    width: 10rem;
}

.award-category-label {
    overflow-wrap: break-word;
    word-break: break-all;
}

.caps-waivers-tooltip {
    background-color: var(--blue);
    max-width: 100%;
    text-align: left;
    white-space: pre-wrap;
}

#capsAndWaiversTable {
    width: 100%;
}

#capsAndWaiversTable td {
    padding-bottom: 0.3rem;
    padding-top: 0.3rem;
}

.combine-select-container {
    width: 10.1rem;
}

@media only screen and (max-width: 568px) {
    .combine-select-container {
        width: 7.2rem;
    }
}

@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
    #capsAndWaiversTable table, thead, tbody, th, td, tr { /* force table to not be a table anymore */
        display: block;
    }

    #capsAndWaiversTable thead tr { /* hide table headers (without using display, to support accessibility) */
        left: -9999px;
        position: absolute;
        top: -9999px;
    }

    #capsAndWaiversTable tr:first-of-type { /* first row has extra padding already */
        padding-top: 0;
    }

    #capsAndWaiversTable tr {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-bottom: 1rem;
        padding-top: 1rem;
    }
    
    #capsAndWaiversTable td { /* behave like a row */
        margin-top: 0.1rem;
        margin-bottom: 0.1rem;
        min-height: 2rem;
        padding-left: 9rem;
        position: relative;
    }

    #capsAndWaiversTable td:before { /* behave like a table header */
        content: attr(data-header);
        font-weight: bold;
        left: 0rem;
        position: absolute;
        white-space: nowrap;
    }
}

@media (max-width: 576px) {
    .flex-xs-wrap {
        -webkit-flex-wrap: wrap !important;
                flex-wrap: wrap !important;
    }
}

/* custom flex wrap to get combinedAwardLetterSendOptionDate to wrap inline with other combined award dates */
@media (max-width: 769px) {
    .flex-wrap-combinedAwardLetterSendOptionDate {
        -webkit-flex-wrap: wrap !important;
                flex-wrap: wrap !important;
    }
}

.inline-center {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-bottom: 0;
}

.skip-to-content {
    text-align: center;
}

.break-word {
    word-break: break-word;
}
.viewer {
    position: absolute;
    left:0;
    height: 80%;
    width: 100%;
}

.scroll-wrapper-viewer {
    -webkit-overflow-scrolling: touch !important;;
    overflow-y: scroll !important;;
    position: absolute;
    left:0;
    height: 80%;
    width: 100%;

    /* important:  dimensions or positioning here! */
}

.scroll-wrapper-viewer iframe {
    /* nada! */
    height: 100%;
    width: 100%;

}

.display-linebreak {
    white-space: pre-line;
  }

  .formHeadingsLarge {
    font-size: 1.25rem;
    letter-spacing: 0.15px;
    font-weight: 600;
}

.formHeadingsMedium {
    font-size: 1.00rem;
    letter-spacing: 0.15px;
    font-weight: 600;
}

.formHeadingsSmall {
    font-size: 0.75rem;
    letter-spacing: 0.15px;
    font-weight: 600;
}

.submitButton {
    width: 6em;
    height: 2em;
}

.logo {
    -webkit-align-content: center;
            align-content: center;
}

.errorOrLoadingText {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    height: calc(100vh - 55px); 
}

.file-button {
  background: #ffffff;
  border: solid 1px var(--blue);
  color: var(--blue);
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out,  -webkit-transform 150ms ease;
  transition: background 250ms ease-in-out,  transform 150ms ease;
  transition: background 250ms ease-in-out,  transform 150ms ease,  -webkit-transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-weight: 600;
  border-radius: 0.25rem;
  font-size: .875rem;
  padding: .25rem .5rem; }
  .file-button:hover {
    background: var(--blue-dark);
    color: white; }
  .file-button:disabled {
    background: transparent;
    color: var(--blue);
    opacity: .65;
    cursor: default; }

.nom-card-form hr {
  width: 100%; }

